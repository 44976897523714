export const getColorScheme = (colorScheme) => {
  return {
    light: {
      backgroundColor: 'white',
      containerBackgroundColor: 'var(--color-neutral-100)',
      lightContainerBackgroundColor: 'white',
      textColor: 'black',
      red: 'var(--color-red-500)',
      buttonBackgroundColor: 'white',
      selectedBackgroundColor: 'var(--color-green-100)'
    },

    dark: {
      backgroundColor: '#1e1e1e',
      containerBackgroundColor: '#2d2d30',
      lightContainerBackgroundColor: '#3e3e42',
      textColor: 'white',
      red: 'var(--color-red-500)',
      buttonBackgroundColor: '#007acc',
      selectedBackgroundColor: 'var(--color-green-800)'
    }
  }[colorScheme];
};

export const getLanguage = (language) => {
  return {
    DA: {
      NOTE_DOSAGE: 'Noter dose',
      NOTE: 'Noter',
      RESET: 'Reset',
      DOSAGE_HISTORY: 'Dose historik',
      NONE: 'INGEN',
      EDIT_YOUR_SETTINGS: 'Ændre dine indstillinger.',
      LANGUAGE: 'Sprog',
      MODE: 'Tilstand',
      SIMPLE: 'Simpelt',
      ADVANCED: 'Advanceret',
      SIMPLE_DESC: `Du vil blive præsenteret med et simpelt interface, til at holde styr på dine tider, og intet andet.`,
      ADVANCED_DESC: `Udover dine normale funktioner, vil du også have muligheden for at se statistikker, samt flere indstillinger.`,
      SAVE: 'Gem',
      SETUP_DESC: `Lad os sætte siden op for dig. Du kan til hver en tid ændre dine indstillinger igen efter.`,
      SELECT_YOUR_LANGUAGE: '1. Vælg dit sprog',
      SELECT_YOUR_SUBSTANCE: '2. Vælg dit stof',
      SELECT_YOUR_MODE: '3. Vælg din tilstand',
      LETS_GO: `Let's GOOOO!`,
      SETUP_CHOICE: 'Hvis du har lyst, så kan du gå igennem opsætningen, eller du kan bruge standardinstillingerne.',
      USE_DEFAULT_SETTINGS: 'Brug standardindstillinger',
      GO_THROUGH_SETUP: 'Gå igennem opsætning'
    },

    EN: {
      NOTE_DOSAGE: 'Note dosage',
      NOTE: 'Note',
      RESET: 'Reset',
      DOSAGE_HISTORY: 'Dosage history',
      NONE: 'NONE',
      EDIT_YOUR_SETTINGS: 'Edit your settings.',
      LANGUAGE: 'Language',
      MODE: 'Mode',
      SIMPLE: 'Simple',
      ADVANCED: 'Advanced',
      SIMPLE_DESC: `You'll be presented with a simple interface, to track your dosages and times, and nothing else.`,
      ADVANCED_DESC: `Along with default functionality, you'll also be able to see statistics, edit your inputs and edit additional settings.`,
      SAVE: 'Save',
      SETUP_DESC: `Let's set up your experience, before you continue. You can edit your settings at any time after this.`,
      SELECT_YOUR_LANGUAGE: '1. Select your language',
      SELECT_YOUR_SUBSTANCE: '2. Select your substance',
      SELECT_YOUR_MODE: '3. Select your mode',
      LETS_GO: `Let's GOOOO!`,
      SETUP_CHOICE: 'If you want to, you can go through the optional setup, or just go through with the default settings.',
      USE_DEFAULT_SETTINGS: 'Use default settings',
      GO_THROUGH_SETUP: 'Go through setup'
    },

    DE: {
      NOTE_DOSAGE: 'Note dosage',
      NOTE: 'Note',
      RESET: 'Reset',
      DOSAGE_HISTORY: 'Dosage history',
      NONE: 'NONE',
      EDIT_YOUR_SETTINGS: 'Edit your settings.',
      LANGUAGE: 'Language',
      MODE: 'Mode',
      SIMPLE: 'Simple',
      ADVANCED: 'Advanced',
      SIMPLE_DESC: `You'll be presented with a simple interface, to track your dosages and times, and nothing else.`,
      ADVANCED_DESC: `Along with default functionality, you'll also be able to see statistics, edit your inputs and edit additional settings.`,
      SAVE: 'Save',
      SETUP_DESC: `Let's set up your experience, before you continue. You can edit your settings at any time after this.`,
      SELECT_YOUR_LANGUAGE: '1. Select your language',
      SELECT_YOUR_SUBSTANCE: '2. Select your substance',
      SELECT_YOUR_MODE: '3. Select your mode',
      LETS_GO: `Let's GOOOO!`,
      SETUP_CHOICE: 'If you want to, you can go through the optional setup, or just go through with the default settings.',
      USE_DEFAULT_SETTINGS: 'Use default settings',
      GO_THROUGH_SETUP: 'Go through setup'
    }
  }[language];
};
