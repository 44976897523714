import * as React from "react";
import { navigate } from 'gatsby';
import Navbar from '../components/Navbar';
import { Redirect } from '@reach/router';
import { getColorScheme } from "../functions/helper";

// markup
const IndexPage = () => {
  const windowGlobal = typeof window !== 'undefined' && window;
  const firstVisit = windowGlobal.localStorage?.getItem('language') === null || windowGlobal.localStorage?.getItem('substance') === null || windowGlobal.localStorage?.getItem('mode') === null;

  const [colorScheme, setColorScheme] = React.useState(getColorScheme(windowGlobal.localStorage?.getItem('colorScheme') ?? 'light'));
  const [language, setLanguage] = React.useState(windowGlobal.localStorage?.getItem('language') ?? 'EN');
  const [substance, setSubstance] = React.useState(windowGlobal.localStorage?.getItem('substance') ?? 'GBL');
  const [mode, setMode] = React.useState(windowGlobal.localStorage?.getItem('mode') ?? 'simple');
  const [setupInitiated, setSetupInitiated] = React.useState(!firstVisit);

  if (!firstVisit) {
    return (<Redirect to="/" noThrow />);
  }

  const saveSettings = () => {
    windowGlobal.localStorage?.setItem('language', language);
    windowGlobal.localStorage?.setItem('substance', substance);
    windowGlobal.localStorage?.setItem('mode', mode);
    navigate('/');
  };

  return (
    <main style={{ color: colorScheme.textColor, backgroundColor: colorScheme.backgroundColor }}>
      <Navbar />
      <div className="max-w-screen-sm mx-auto px-4">
        <div
          className="my-4 sm:my-10 p-6 sm:p-10"
          style={{ backgroundColor: colorScheme.containerBackgroundColor }}
        >
          <h1 className="italic text-center">It's G Time!</h1>

          {
            setupInitiated ? (
              <>
                <p className="text-center mb-4">
                  Let's set up your experience, before you continue. You can edit your settings at any time after this.
                </p>

                <h2>1. Select your language</h2>
                <div className="grid grid-cols-3 gap-x-4 mb-10">
                  <div
                    className={`option ${language === 'DA' ? 'selected' : ''}`}
                    onClick={() => setLanguage('DA')}
                    style={language !== 'DA' ? { backgroundColor: colorScheme.buttonBackgroundColor } : { backgroundColor: colorScheme.selectedBackgroundColor }}
                  >
                    <h3>
                      <span className="block sm:hidden">
                        🇩🇰
                      </span>

                      <span className="hidden sm:block">
                        🇩🇰 Dansk
                      </span>
                    </h3>
                  </div>

                  <div
                    className={`option ${language === 'EN' ? 'selected' : ''}`}
                    onClick={() => setLanguage('EN')}
                    style={language !== 'EN' ? { backgroundColor: colorScheme.buttonBackgroundColor } : { backgroundColor: colorScheme.selectedBackgroundColor }}
                  >
                    <h3>
                      <span className="block sm:hidden">
                        🇬🇧
                      </span>

                      <span className="hidden sm:block">
                        🇬🇧 English
                      </span>
                    </h3>
                  </div>

                  <div
                    className={`option ${language === 'DE' ? 'selected' : ''}`}
                    onClick={() => setLanguage('DE')}
                    style={language !== 'DE' ? { backgroundColor: colorScheme.buttonBackgroundColor } : { backgroundColor: colorScheme.selectedBackgroundColor }}
                  >
                    <h3>
                      <span className="block sm:hidden">
                        🇩🇪
                      </span>

                      <span className="hidden sm:block">
                        🇩🇪 Deutsch
                      </span>
                    </h3>
                  </div>
                </div>

                <h2>2. Select your substance</h2>
                <div className="grid grid-cols-3 gap-x-4 mb-10">
                  <div
                    className={`option ${substance === 'GHB' ? 'selected' : ''}`}
                    onClick={() => setSubstance('GHB')}
                    style={substance !== 'GHB' ? { backgroundColor: colorScheme.buttonBackgroundColor } : { backgroundColor: colorScheme.selectedBackgroundColor }}
                  >
                    <h3>GHB</h3>
                  </div>

                  <div
                    className={`option ${substance === 'GBL' ? 'selected' : ''}`}
                    onClick={() => setSubstance('GBL')}
                    style={substance !== 'GBL' ? { backgroundColor: colorScheme.buttonBackgroundColor } : { backgroundColor: colorScheme.selectedBackgroundColor }}
                  >
                    <h3>GBL</h3>
                  </div>

                  <div
                    className={`option ${substance === 'BDO' ? 'selected' : ''}`}
                    onClick={() => setSubstance('BDO')}
                    style={substance !== 'BDO' ? { backgroundColor: colorScheme.buttonBackgroundColor } : { backgroundColor: colorScheme.selectedBackgroundColor }}
                  >
                    <h3>BDO</h3>
                  </div>
                </div>

                <h2>3. Select your mode</h2>
                <div className="grid grid-cols-2 gap-x-4 mb-10">
                  <div
                    className={`flex items-center option justify-center ${mode == 'simple' ? 'selected' : ''}`}
                    onClick={() => setMode('simple')}
                    style={mode !== 'simple' ? { backgroundColor: colorScheme.buttonBackgroundColor } : { backgroundColor: colorScheme.selectedBackgroundColor }}
                  >
                    <div>
                      <div className="text-center text-xl font-semibold">
                        Simple
                      </div>

                      <div className="hidden sm:block">
                        You'll be presented with a simple interface, to track your dosages and times, and nothing else.
                      </div>
                    </div>
                  </div>

                  <div
                    className={`flex items-center justify-center option ${mode == 'advanced' ? 'selected' : ''}`}
                    onClick={() => setMode('advanced')}
                    style={mode !== 'advanced' ? { backgroundColor: colorScheme.buttonBackgroundColor } : { backgroundColor: colorScheme.selectedBackgroundColor }}
                  >
                    <div>
                      <div className="text-center text-xl font-semibold">
                        Advanced
                      </div>

                      <div className="hidden sm:block">
                        Along with default functionality, you'll also be able to see statistics, edit your inputs and edit additional settings.
                      </div>
                    </div>
                  </div>

                  <div className="block sm:hidden mt-4 col-span-2">
                    {
                      mode == 'simple' ? `You'll be presented with a simple interface, to track your dosages and times, and nothing else.`
                        : mode == 'advanced' ? `Along with default functionality, you'll also be able to see statistics and edit your inputs.`
                          : 'Unknown mode ?_?'
                    }
                  </div>
                </div>

                <button
                  className="big"
                  onClick={saveSettings}
                  style={{ backgroundColor: colorScheme.buttonBackgroundColor }}
                >
                  Let's GOOOO
                </button>
              </>
            ) : (
              <div>
                <p className="mb-4">
                  If you want to, you can go through the optional setup, or just go through with the default settings.
                </p>

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <button
                    className="big"
                    onClick={saveSettings}
                    style={{ backgroundColor: colorScheme.buttonBackgroundColor }}
                  >
                    Use default settings
                  </button>

                  <button
                    className="big"
                    onClick={() => setSetupInitiated(true)}
                    style={{ backgroundColor: colorScheme.buttonBackgroundColor }}
                  >
                    Go through setup
                  </button>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </main>
  )
}

export default IndexPage
