import { Link } from "gatsby";
import * as React from "react";

const Navbar = () => {
  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={1} />
      <link href="https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet" />

      <title>It's G Time</title>
      <div className="h-16 bg-black/10">
        <nav className="flex max-w-screen-sm mx-auto items-center h-full justify-center gap-x-4">
          <Link to='/'>Frontpage</Link>
          <Link to='/settings'>Settings</Link>
        </nav>
      </div>
    </>
  )
};

export default Navbar;
